import { createAction } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from 'reduxTyped'
import { createSelector } from 'reselect'
import { l } from '@manychat/manyui'

import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { BulkTypes, BulkOperation } from 'apps/cms/lib/bulk'
import { FsQueryOrderType } from 'apps/cms/lib/constants'
import { getQueryStringFromFsQuery } from 'apps/cms/lib/query'
import { namespace, ContentManagementSystemSocketEvents } from 'apps/cms/store/lib/constants'
import { isFsFlowObject, parseFsFlowObject } from 'apps/cms/store/lib/helper'
import { initialState } from 'apps/cms/store/lib/initialState'
import { mapSocketFsFlow, mapSocketFsFlowDeleted, isFlowInPath } from 'apps/cms/store/lib/lib'
import { SocketFsFlow, SocketFsFlowDeleted } from 'apps/cms/store/lib/types'
import { cmsQuerySelectors } from 'apps/cms/store/query'
import { cmsUiActions, cmsUiSelectors } from 'apps/cms/store/ui'
import { SELECTION_TYPE } from 'common/cms/constants/CMSSelectionType'
import { FSSmartFolders, FSQueryRootPath } from 'common/cms/models/FSObject/constants'
import { alert } from 'common/core'
import { ChannelType } from 'common/core/constants/ChannelType'
import { IThunkAction } from 'common/core/interfaces/actions'
import * as flowActions from 'common/flow/actions/flowActions'
import { FlowChannel } from 'common/flow/interfaces/channels'
import { handleCatch } from 'shared/api/lib/errors/handlers'
import { CmsApi } from 'shared/api/requests/cms'
import { FsFlowObject, FsFlow } from 'shared/api/requests/cms/schemas'
import { getClientId } from 'utils/clientId'
import { navigatePollyfill } from 'utils/router/tools'
import { anotherTabNotificationsListener } from 'utils/services/notificationsService'
import { linkURL } from 'utils/url'

const updateFlows = createAction<FsFlow[]>(`${namespace}/updateFlows`)

const removeFlow = createAction<FsFlow>(`${namespace}/removeFlow`)

const updateFlowName = createAction<{ ns: string; name: string }>(`${namespace}/updateFlowName`)

const unselectFlowByPath = createAction<{ path: string }>(`${namespace}/unselectFlowByPath`)

const getFlows = createAppAsyncThunk(
  `${namespace}/getFlows`,
  async ({ limiter }: { limiter: number | string | null }, { getState }) => {
    const state = getState()

    const { path, order, field } = cmsQuerySelectors.getQuery(state)

    try {
      const response = await CmsApi.getFlows({
        query: {
          path,
          field,
          order,
          limiter,
        },
      })

      return {
        flows: response.data.list,
        limiter: response.data.limiter,
        accountHasFlows: response.data.has_any_flows,
      }
    } catch (error) {
      handleCatch(error)
      const params = getQueryStringFromFsQuery(initialState.query)
      navigatePollyfill(linkURL(`cms${params}`))

      return {
        flows: [],
        limiter: null,
        accountHasFlows: false,
      }
    }
  },
)

const searchFlows = createAppAsyncThunk(
  `${namespace}/searchFlows`,
  async ({ limiter }: { limiter: number | string | null }, { getState }) => {
    const state = getState()

    const { path, order, search, field, triggerFilters, triggerStatus } =
      cmsQuerySelectors.getQuery(state)

    try {
      const response = await CmsApi.searchFlows({
        body: {
          path,
          q: search,
          field,
          order,
          trigger_filters: triggerFilters,
          trigger_status: triggerStatus,
          limiter,
        },
      })

      CmsAnalytics.logSearchFlows({
        search,
        triggerFilters,
        triggerStatus,
      })

      return {
        flows: response.data.list,
        limiter: response.data.limiter,
        accountHasFlows: true,
      }
    } catch (error) {
      handleCatch(error)
      const params = getQueryStringFromFsQuery(initialState.query)
      navigatePollyfill(linkURL(`cms${params}`))

      return {
        flows: [],
        limiter: null,
        accountHasFlows: false,
      }
    }
  },
)

const fetchFlows = createAppAsyncThunk(
  `${namespace}/fetchFlows`,
  async (_: void, { getState, dispatch }) => {
    const state = getState()
    const isSearchMode = cmsQuerySelectors.getIsSearchMode(state)

    const action = isSearchMode ? searchFlows : getFlows

    const result = await dispatch(action({ limiter: null })).unwrap()

    return result
  },
)

const fetchFlowsNextPage = createAppAsyncThunk(
  `${namespace}/fetchFlowsNextPage`,
  async (_: void, { getState, dispatch }) => {
    const state = getState()
    dispatch(cmsUiActions.setLastSelectedFlowId(null))
    const { limiter } = cmsFlowsSelectors.getFlowsState(state)

    const isSearchMode = cmsQuerySelectors.getIsSearchMode(state)
    const action = isSearchMode ? searchFlows : getFlows

    const result = await dispatch(action({ limiter })).unwrap()

    return {
      flows: result.flows,
      limiter: result.limiter,
    }
  },
)

const createAutomation = createAppAsyncThunk(
  `${namespace}/createAutomation`,
  async (options?: { name?: string; path?: string | null; quickCampaignId?: number }) => {
    try {
      const { data } = await CmsApi.createFlow({
        body: {
          name: options?.name || l.translate('Untitled'),
          path: options?.path || '/',
          quick_campaign_id: options?.quickCampaignId,
        },
      })

      return data
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const deleteFlowByPath = createAppAsyncThunk(
  `${namespace}/deleteFlowByPath`,
  async (path: string, { dispatch }) => {
    try {
      const { data } = await CmsApi.delete({ query: { path, client_id: getClientId() } })

      alert(l.translate('Automation deleted'), 'success')

      dispatch(unselectFlowByPath({ path: data.path }))

      return {
        path: data.path,
      }
    } catch (error) {
      handleCatch(error)
      return {
        path: null,
      }
    }
  },
)

const restoreFlow = createAppAsyncThunk(
  `${namespace}/restoreFlow`,
  async (path: string, { dispatch }) => {
    try {
      const { data } = await CmsApi.restore({ query: { path, client_id: getClientId() } })

      alert(l.translate('Automation restored'), 'success')

      dispatch(unselectFlowByPath({ path }))

      return {
        path: data.path,
      }
    } catch (error) {
      handleCatch(error)
      return {
        path: null,
      }
    }
  },
)

const renameFlow = createAppAsyncThunk(
  `${namespace}/renameFlow`,
  async ({ path, title }: { path: string; title: string }) => {
    try {
      const { data } = await CmsApi.renameObject({
        body: {
          path,
          title,
          client_id: getClientId(),
        },
      })

      alert(l.translate('Automation renamed'), 'success')

      if (!isFsFlowObject(data.fs_object)) return null

      const parsedFlow = parseFsFlowObject(data.fs_object)

      return parsedFlow
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const cloneFlow = createAppAsyncThunk(
  `${namespace}/cloneFlow`,
  async (path: string, { getState }) => {
    try {
      const { data } = await CmsApi.clone({ query: { path, client_id: getClientId() } })

      if (!isFsFlowObject(data.fs_object)) return null

      const state = getState()
      const isTrashFolder = cmsQuerySelectors.getIsTrashFolder(state)
      const parsedFlow = parseFsFlowObject(data.fs_object)

      alert(l.translate('Automation duplicated'), 'success')

      return isTrashFolder ? null : parsedFlow
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const bulkClone = createAppAsyncThunk(`${namespace}/bulkClone`, async (_, { getState }) => {
  const state = getState()
  const selectedFlows = cmsUiSelectors.getSelectedFlow(state)
  const clientId = getClientId()

  try {
    const { data } = await CmsApi.bulkClone({
      body: {
        paths: selectedFlows.map((flow) => flow.path),
        client_id: clientId,
      },
    })

    const fsObjects = data.fs_objects.filter((fsObject) =>
      isFsFlowObject(fsObject),
    ) as FsFlowObject[]

    if (!fsObjects.length) return null

    const parsedFlows = fsObjects.map(parseFsFlowObject)

    alert('Automations duplicated', 'success')

    return parsedFlows
  } catch (error) {
    handleCatch(error)
    return null
  }
})

const permanentlyRemoveFlow = createAppAsyncThunk(
  `${namespace}/permanentlyRemoveFlow`,
  async (path: string, { dispatch }) => {
    try {
      await CmsApi.permanentlyDelete({ query: { path } })

      alert(l.translate('Automation permanently deleted'), 'success')

      dispatch(unselectFlowByPath({ path }))

      return { path }
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const updateFlowSharingSettings = createAppAsyncThunk(
  `${namespace}/updateFlowSharingSettings`,
  async ({ flowId, sharingEnabled }: { flowId: string; sharingEnabled: boolean }, { dispatch }) => {
    const { player_embed_url, player_page_url } = await dispatch(
      flowActions.updateFlowShared(flowId, sharingEnabled),
    )

    return { flowId, sharingEnabled, player_embed_url, player_page_url }
  },
)

const updateFlowSharingCloningSettings = createAppAsyncThunk(
  `${namespace}/updateFlowSharingCloningSettings`,
  async ({ flowId, cloningEnabled }: { flowId: string; cloningEnabled: boolean }, { dispatch }) => {
    await dispatch(flowActions.updateFlowSharedCloning(flowId, cloningEnabled))

    return { flowId, cloningEnabled }
  },
)

const bulkDeleteFlows = createAppAsyncThunk(
  `${namespace}/bulkDeleteFlows`,
  async (_, { getState, dispatch }) => {
    const state = getState()
    const path = cmsQuerySelectors.getQueryPath(state)
    const selectedFlows = cmsUiSelectors.getSelectedFlowIds(state)
    const flowsSelectedAmount = selectedFlows.length
    const isTrashFolder = path === FSSmartFolders.TRASH
    const typeOperation = isTrashFolder ? BulkTypes.PERMANENTLY_DELETE : BulkTypes.DELETE
    const clientId = getClientId()
    const MAX_AMOUNT_OF_FLOWS_TO_REFRESH_FLOWS = 10

    try {
      await CmsApi.bulk({
        body: {
          path,
          type: typeOperation,
          flow_nses: selectedFlows,
          selection_type: SELECTION_TYPE.INCLUDE,
          client_id: clientId,
        },
      })

      if (flowsSelectedAmount >= MAX_AMOUNT_OF_FLOWS_TO_REFRESH_FLOWS) {
        await dispatch(fetchFlows())
      }

      alert(
        l.translate(`{flowAmount} Automations have been {BulkOperationDataType}`, {
          flowAmount: flowsSelectedAmount,
          BulkOperationDataType: l.getString(BulkOperation[typeOperation]),
        }),
        'success',
      )

      CmsAnalytics.logBulkDelete({ count: flowsSelectedAmount, type: typeOperation })

      return selectedFlows
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const bulkRestoreFlows = createAppAsyncThunk(
  `${namespace}/bulkRestoreFlows`,
  async (_, { getState, dispatch }) => {
    const state = getState()
    const path = cmsQuerySelectors.getQueryPath(state)
    const selectedFlows = cmsUiSelectors.getSelectedFlowIds(state)
    const clientId = getClientId()
    const MAX_AMOUNT_OF_FLOWS_TO_REFRESH_FLOWS = 10

    try {
      await CmsApi.bulk({
        body: {
          path,
          type: BulkTypes.RESTORE,
          flow_nses: selectedFlows,
          selection_type: SELECTION_TYPE.INCLUDE,
          client_id: clientId,
        },
      })

      if (selectedFlows.length >= MAX_AMOUNT_OF_FLOWS_TO_REFRESH_FLOWS) {
        await dispatch(fetchFlows())
      }

      alert(l.translate('Automations restored'), 'success')

      return selectedFlows
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const moveFlow = createAppAsyncThunk(
  `${namespace}/moveFlow`,
  async (
    {
      path,
      pathToMove,
      customSuccessAlert,
    }: {
      path: string
      pathToMove: string
      customSuccessAlert?: (text: string, path: string) => void
    },
    { dispatch },
  ) => {
    try {
      await CmsApi.move({
        query: {
          path,
          to: pathToMove,
        },
      })

      if (customSuccessAlert) {
        customSuccessAlert(l.translate('Automation moved to folder'), pathToMove)
      } else {
        alert(l.translate('Automation moved to folder'), 'success')
      }

      dispatch(unselectFlowByPath({ path }))

      return { path }
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

const bulkMoveTo = createAppAsyncThunk(
  `${namespace}/bulkMoveTo`,
  async (
    {
      pathMoveTo,
      customSuccessAlert,
    }: { pathMoveTo: string; customSuccessAlert?: (text: string, path: string) => void },
    { getState, dispatch },
  ) => {
    const state = getState()
    const selectedFlows = cmsUiSelectors.getSelectedFlow(state)
    const clientId = getClientId()
    const MAX_AMOUNT_OF_FLOWS_TO_REFRESH_FLOWS = 10

    try {
      await CmsApi.bulkMove({
        body: {
          paths: selectedFlows.map((flow) => flow.path),
          to: pathMoveTo,
          client_id: clientId,
        },
      })

      if (selectedFlows.length >= MAX_AMOUNT_OF_FLOWS_TO_REFRESH_FLOWS) {
        await dispatch(fetchFlows())
      }

      if (customSuccessAlert) {
        customSuccessAlert(l.translate('Automations moved to folder'), pathMoveTo)
      } else {
        alert(l.translate('Automations moved to folder'), 'success')
      }

      return selectedFlows.map((flow) => flow.ns)
    } catch (error) {
      handleCatch(error)
      return null
    }
  },
)

export const cmsFlowsActions = {
  updateFlows,
  removeFlow,
  updateFlowName,
  unselectFlowByPath,
  fetchFlows,
  fetchFlowsNextPage,
  createAutomation,
  deleteFlowByPath,
  restoreFlow,
  renameFlow,
  cloneFlow,
  bulkClone,
  permanentlyRemoveFlow,
  updateFlowSharingSettings,
  updateFlowSharingCloningSettings,
  bulkDeleteFlows,
  bulkRestoreFlows,
  moveFlow,
  bulkMoveTo,
}

const getState = (state: RootState) => state.contentManagementSystem.flows

export const cmsFlowsSelectors = {
  getFlowsState: (state: RootState) => getState(state),

  getFlows: (state: RootState) => getState(state).list,

  getIsFlowCreating: (state: RootState) => getState(state).isCreating,

  getFlowIds: createSelector([(state: RootState) => getState(state).list], (flowIdsList) =>
    flowIdsList.map((flow) => flow.ns),
  ),

  getFlowByPath: createSelector(
    [(state: RootState) => getState(state).list, (_state: RootState, ns: string | null) => ns],
    (flows, path) => {
      if (!path) return null
      return flows.find((flow) => flow.path === path)
    },
  ),

  getFlowById: createSelector(
    [(state: RootState) => getState(state).list, (_state: RootState, ns: string | null) => ns],
    (flows, ns) => {
      if (!ns) return null
      return flows.find((flow) => flow.ns === ns)
    },
  ),

  getFlowUsedChannelsList: createSelector(
    [
      (state: RootState, flowId: string) => {
        const flow = getState(state).list.find((flow) => flow.ns === flowId)

        if (!flow) {
          return []
        }

        return Object.entries(flow.channels_usage).reduce<FlowChannel[]>(
          (usedChannels, [channel, isUsed]) => {
            if (isUsed && channel !== ChannelType.EMAIL) {
              usedChannels.push(channel as FlowChannel)
            }

            return usedChannels
          },
          [],
        )
      },
    ],
    (usedChannels) => usedChannels,
  ),
}

export const updateFlowsListenerAction = (flowFromWS: FsFlow): IThunkAction => {
  return (dispatch, getState) => {
    const state = getState()

    const { order } = cmsQuerySelectors.getQuery(state)
    const flows = cmsFlowsSelectors.getFlows(state)
    const { limiter } = cmsFlowsSelectors.getFlowsState(state)

    if (order === FsQueryOrderType.ASC && limiter !== null) return

    const flowsWithRestoredFlowInCorrectOrder =
      order === FsQueryOrderType.DESC ? [flowFromWS, ...flows] : [...flows, flowFromWS]

    dispatch(updateFlows(flowsWithRestoredFlowInCorrectOrder))

    return
  }
}

export const deleteFlowListenerAction = (flowFromWS: FsFlow): IThunkAction => {
  return (dispatch, getState) => {
    const state = getState()
    const { isLoaded } = cmsFlowsSelectors.getFlowsState(state)

    if (!isLoaded) return

    const { path } = cmsQuerySelectors.getQuery(state)

    if (path.includes(FSSmartFolders.TRASH)) {
      dispatch(updateFlowsListenerAction(flowFromWS))
    } else {
      dispatch(removeFlow(flowFromWS))
    }
  }
}

export const restoreFlowListenerAction = (flowFromWs: FsFlow): IThunkAction => {
  return (dispatch, getState) => {
    const state = getState()

    const { isLoaded } = cmsFlowsSelectors.getFlowsState(state)

    if (!isLoaded) return

    const { path } = cmsQuerySelectors.getQuery(state)
    const flows = cmsFlowsSelectors.getFlows(state)

    const isTrashFolder = path.includes(FSSmartFolders.TRASH)
    const hasFlowInList = flows.some((flow) => flow.ns === flowFromWs.ns)

    if (path === FSQueryRootPath || (isTrashFolder && !hasFlowInList)) {
      dispatch(updateFlowsListenerAction(flowFromWs))
      return
    }

    if (hasFlowInList) {
      dispatch(removeFlow(flowFromWs))
    }
  }
}

anotherTabNotificationsListener.on(
  ContentManagementSystemSocketEvents.FS_FLOW_DELETED,
  (data: { flow: SocketFsFlow }, dispatch) => {
    dispatch(deleteFlowListenerAction(mapSocketFsFlow(data.flow)))
  },
)

anotherTabNotificationsListener.on(
  ContentManagementSystemSocketEvents.FS_FLOW_RESTORED,
  (data: { flow: SocketFsFlow }, dispatch) => {
    dispatch(restoreFlowListenerAction(mapSocketFsFlow(data.flow)))
  },
)

anotherTabNotificationsListener.on(
  ContentManagementSystemSocketEvents.FS_FLOW_PERMANENTLY_DELETED,
  (data: { flow: SocketFsFlowDeleted }, dispatch, getState) => {
    const state = getState() as RootState
    const { isLoaded } = cmsFlowsSelectors.getFlowsState(state)

    if (!isLoaded) return

    dispatch(removeFlow(mapSocketFsFlowDeleted(data.flow)))
  },
)

anotherTabNotificationsListener.on(
  ContentManagementSystemSocketEvents.FS_FLOW_RENAMED,
  (data: { flow: SocketFsFlow }, dispatch, getState) => {
    const state = getState() as RootState
    const { isLoaded } = cmsFlowsSelectors.getFlowsState(state)

    if (!isLoaded) return

    dispatch(updateFlowName({ ns: data.flow.ns, name: data.flow.name }))
  },
)

anotherTabNotificationsListener.on(
  [
    ContentManagementSystemSocketEvents.FS_FLOW_CREATED,
    ContentManagementSystemSocketEvents.EB_FLOW_CREATED,
  ],
  (data: { flow: SocketFsFlow }, dispatch, getState) => {
    const state = getState() as RootState
    const { isLoaded } = cmsFlowsSelectors.getFlowsState(state)

    if (!isLoaded) return

    const currentPath = cmsQuerySelectors.getQueryPath(state)

    if (isFlowInPath({ path: currentPath, flowPath: data.flow.path })) {
      dispatch(updateFlowsListenerAction(mapSocketFsFlow(data.flow)))
    }
  },
)

anotherTabNotificationsListener.on(
  ContentManagementSystemSocketEvents.FS_FLOW_MOVED,
  (data: { flow: SocketFsFlow }, dispatch, getState) => {
    const state = getState() as RootState
    const { isLoaded } = cmsFlowsSelectors.getFlowsState(state)

    if (!isLoaded) return

    const flow = mapSocketFsFlow(data.flow)
    const currentPath = cmsQuerySelectors.getQueryPath(state)

    if (isFlowInPath({ path: currentPath, flowPath: data.flow.path })) {
      dispatch(updateFlowsListenerAction(flow))
    } else {
      dispatch(removeFlow(flow))
    }
  },
)
