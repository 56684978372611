import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'

import { isIgCgt } from 'apps/cms/components/Flows/components/FlowCard/components/Triggers/hooks/useTriggers/lib/filtersMatching/instagram'
import { FsFolderOrderField } from 'apps/cms/lib/constants'
import { cmsCombinedActions } from 'apps/cms/store/combined'
import { cmsFiltersActions, cmsFiltersSelectors } from 'apps/cms/store/filters'
import { cmsFlowsSelectors, cmsFlowsActions } from 'apps/cms/store/flows'
import { cmsFoldersSelectors, cmsFoldersActions } from 'apps/cms/store/folders'
import { initialState } from 'apps/cms/store/lib/initialState'
import { orderFolderByParam } from 'apps/cms/store/lib/lib'
import { cmsModalsActions, cmsModalsSelectors } from 'apps/cms/store/modals'
import { cmsQueryActions, cmsQuerySelectors } from 'apps/cms/store/query'
import { cmsUiActions, cmsUiSelectors } from 'apps/cms/store/ui'

export const { reducer: contentManagementSystemReducer, actions } = createSlice({
  name: 'contentManagementSystem',
  reducers: {
    resetState: () => initialState,
    setIsInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload
    },
  },
  initialState,
  extraReducers: function (builder) {
    builder.addCase(cmsFlowsActions.updateFlows, (state, action) => {
      state.flows.list = action.payload
    })

    builder.addCase(cmsFlowsActions.removeFlow, (state, action) => {
      state.flows.list = state.flows.list.filter((flow) => flow.ns !== action.payload.ns)

      state.ui.selectedFlowIds = state.ui.selectedFlowIds.filter(
        (selectedFlowId) => selectedFlowId !== action.payload.ns,
      )
    })

    builder.addCase(cmsFlowsActions.updateFlowName, (state, action) => {
      state.flows.list = state.flows.list.map((flow) => {
        if (flow.ns !== action.payload.ns) return flow
        return {
          ...flow,
          name: action.payload.name,
        }
      })
    })

    builder.addCase(cmsFlowsActions.fetchFlows.pending, (state, action) => {
      state.flows = { ...initialState.flows, isLoading: true, lastRequestId: action.meta.requestId }
    })
    builder.addCase(cmsFlowsActions.fetchFlows.fulfilled, (state, action) => {
      if (action.meta.requestId !== state.flows.lastRequestId) {
        return
      }

      state.flows.isLoaded = true
      state.flows.isLoading = false
      state.flows.list = action.payload.flows
      state.flows.limiter = action.payload.limiter
      state.flows.accountHasFlows = action.payload.accountHasFlows
    })

    builder.addCase(cmsFlowsActions.fetchFlowsNextPage.pending, (state, action) => {
      state.flows.isLoading = true
      state.flows.lastRequestId = action.meta.requestId
    })
    builder.addCase(cmsFlowsActions.fetchFlowsNextPage.fulfilled, (state, action) => {
      if (action.meta.requestId !== state.flows.lastRequestId) {
        return
      }

      state.flows.list = [...state.flows.list, ...action.payload.flows]
      state.flows.limiter = action.payload.limiter
      state.flows.isLoading = false
    })

    builder.addCase(cmsFlowsActions.renameFlow.fulfilled, (state, action) => {
      if (action.payload === null) return

      state.flows.list = state.flows.list.map((flow) => {
        if (flow.path !== action.payload?.path) return flow
        return {
          ...flow,
          name: action.payload.name,
          modified: action.payload.modified,
        }
      })
    })

    builder.addCase(cmsFlowsActions.cloneFlow.fulfilled, (state, action) => {
      if (action.payload === null) return

      state.flows.list = [action.payload, ...state.flows.list]
    })

    builder.addCase(cmsFlowsActions.bulkClone.fulfilled, (state, action) => {
      if (action.payload === null) return

      state.ui.selectedFlowIds = []
      state.flows.list = [...action.payload, ...state.flows.list]
    })

    builder.addCase(cmsFlowsActions.updateFlowSharingSettings.fulfilled, (state, action) => {
      const flowToChange = state.flows.list.find((flow) => flow.ns === action.payload.flowId)

      if (flowToChange) {
        flowToChange.sharing.is_shared = action.payload.sharingEnabled
        flowToChange.sharing.player_page_url = action.payload.player_page_url
        flowToChange.sharing.player_embed_url = action.payload.player_embed_url
      }
    })

    builder.addCase(cmsFlowsActions.updateFlowSharingCloningSettings.fulfilled, (state, action) => {
      const flowToChange = state.flows.list.find((flow) => flow.ns === action.payload.flowId)

      if (flowToChange) {
        flowToChange.sharing.shared_cloning_enabled = action.payload.cloningEnabled
      }
    })

    builder.addCase(cmsFlowsActions.unselectFlowByPath, (state, action) => {
      const flow = state.flows.list.find((flow) => flow.path === action.payload.path)

      if (!flow) {
        return
      }

      state.ui.selectedFlowIds = state.ui.selectedFlowIds.filter((flowId) => flow.ns !== flowId)
    })

    builder.addCase(cmsFlowsActions.createAutomation.pending, (state) => {
      state.flows.isCreating = true
    })

    builder.addCase(cmsFlowsActions.createAutomation.fulfilled, (state) => {
      state.flows.isCreating = false
    })

    builder.addCase(cmsFoldersActions.insertFolder, (state, action) => {
      state.folders.list = orderFolderByParam(
        [...state.folders.list, action.payload],
        FsFolderOrderField.TITLE,
      )
    })

    builder.addCase(cmsFoldersActions.rewriteFolder, (state, action) => {
      state.folders.list = orderFolderByParam(
        state.folders.list.map((folder) => {
          if (folder.folder_id === action.payload.folder_id) {
            return action.payload
          }

          return folder
        }),
        FsFolderOrderField.TITLE,
      )
    })

    builder.addCase(cmsFoldersActions.deleteFolderById, (state, action) => {
      state.folders.list = state.folders.list.filter(
        (folder) => folder.folder_id !== action.payload,
      )
    })

    builder.addCase(cmsFoldersActions.moveFolderWithNestedContent, (state, action) => {
      const movedFolderNewData = action.payload
      const movedFolderOldData = state.folders.list.find(
        (folder) => folder.folder_id === movedFolderNewData.folder_id,
      )

      if (!movedFolderOldData) {
        return
      }

      state.folders.list = state.folders.list.map((folder) => {
        if (folder.folder_id === movedFolderNewData.folder_id) {
          return movedFolderNewData
        }

        if (folder.path.includes(String(movedFolderNewData.folder_id))) {
          folder.path = folder.path.replace(movedFolderOldData.path, movedFolderNewData.path)
        }

        return folder
      })
    })

    builder.addCase(cmsFoldersActions.fetchFolders.pending, (state) => {
      state.folders.isLoading = true
    })

    builder.addCase(cmsFoldersActions.fetchFolders.fulfilled, (state, action) => {
      state.folders.list = action.payload
      state.folders.isLoaded = true
    })

    builder.addCase(cmsFoldersActions.createFolder.fulfilled, (state, action) => {
      if (action.payload) {
        state.folders.list = orderFolderByParam(
          [...state.folders.list, action.payload],
          FsFolderOrderField.TITLE,
        )
      }
    })

    builder.addCase(cmsFoldersActions.deleteFolderWithNestedContent, (state, action) => {
      state.folders.list = state.folders.list.filter(
        (folder) => !folder.path.includes(String(action.payload.folderPath)),
      )
    })

    builder.addCase(cmsFoldersActions.renameFolder.fulfilled, (state, { payload }) => {
      if (!payload) {
        return
      }

      state.folders.list = orderFolderByParam(
        state.folders.list.map((folder) => {
          if (folder.path === payload.path) {
            return payload
          }

          return folder
        }),
        FsFolderOrderField.TITLE,
      )
    })

    builder.addCase(cmsFoldersActions.cloneFolder.fulfilled, (state, action) => {
      if (action.payload === null) return

      const isFolderExist = state.folders.list.some(
        (folder) => folder.folder_id === action.payload?.folder_id,
      )

      if (isFolderExist) return

      state.folders.list = orderFolderByParam(
        [...state.folders.list, action.payload],
        FsFolderOrderField.TITLE,
      )
    })

    builder.addCase(cmsFiltersActions.fetchFilters.pending, (state) => {
      state.filters.isLoading = true
    })

    builder.addCase(cmsFiltersActions.fetchFilters.fulfilled, (state, action) => {
      const triggerType = action.payload.trigger_type
      const triggerStatus = action.payload.trigger_status

      state.filters.triggerTypeFilters = triggerType
      state.filters.triggerStatusFilters = triggerStatus
      state.filters.isLoaded = true
      state.filters.isLoading = false

      state.query.triggerFilters = state.query.triggerFilters.filter((queryFilter) =>
        triggerType.find((backendFilter) => {
          if (isIgCgt(queryFilter) && isIgCgt(backendFilter.filters)) {
            return true
          }

          return isEqual(backendFilter.filters, queryFilter)
        }),
      )
    })

    builder.addCase(cmsQueryActions.updateQuery, (state, action) => {
      state.isInitialized = true
      state.query = {
        ...merge(state.query, action.payload),
        triggerFilters: action.payload.triggerFilters ?? state.query.triggerFilters,
      }
      state.ui.selectedFlowIds = []
      state.ui.lastToggledFlowId = null
      state.ui.lastSelectedFlowId = null
    })

    builder.addCase(cmsUiActions.setViewDisplayMode, (state, action) => {
      state.ui.viewDisplayMode = action.payload
      state.ui.selectedFlowIds = []
    })

    builder.addCase(cmsUiActions.setLastSelectedFlowId, (state, action) => {
      state.ui.lastSelectedFlowId = action.payload
    })

    builder.addCase(cmsUiActions.setIsFlowCreationStarted, (state, action) => {
      state.ui.isFlowCreationStarted = action.payload
    })

    builder.addCase(cmsUiActions.toggleFlowSelection, (state, action) => {
      state.ui.lastToggledFlowId = action.payload

      if (state.ui.selectedFlowIds.includes(action.payload)) {
        state.ui.selectedFlowIds = state.ui.selectedFlowIds.filter(
          (flowId) => flowId !== action.payload,
        )
      } else {
        state.ui.selectedFlowIds.push(action.payload)
      }
    })

    builder.addCase(cmsUiActions.initUi, (state, action) => {
      state.ui = merge(state.ui, action.payload)
    })

    builder.addCase(cmsUiActions.resetUi, (state) => {
      state.ui = initialState.ui
    })

    builder.addCase(cmsUiActions.toggleFlowsSelection, (state) => {
      const flowsCount = state.flows.list.length
      const selectedFlowsCount = state.ui.selectedFlowIds.length

      if (flowsCount === selectedFlowsCount) {
        state.ui.selectedFlowIds = []
        state.ui.lastToggledFlowId = null
        return
      }

      state.ui.selectedFlowIds = state.flows.list.map((flow) => flow.ns)

      state.ui.lastToggledFlowId = null
    })

    builder.addCase(cmsUiActions.selectFlowsInRange, (state, action) => {
      let rangeStart: number | undefined
      let rangeEnd: number | undefined

      state.flows.list.forEach((flow, index) => {
        if (action.payload === flow.ns || state.ui.lastToggledFlowId === flow.ns) {
          if (rangeStart !== undefined) {
            rangeEnd = index
          } else {
            rangeStart = index
          }
        }
      })

      if (rangeStart === undefined || rangeEnd === undefined) {
        return
      }

      state.flows.list.forEach((flow, index) => {
        if (
          index >= (rangeStart as number) &&
          index <= (rangeEnd as number) &&
          !state.ui.selectedFlowIds.includes(flow.ns)
        ) {
          state.ui.selectedFlowIds.push(flow.ns)
        }
      })
    })

    builder.addCase(cmsModalsActions.openModal, (state, action) => {
      state.modals[action.payload.type] = {
        isOpen: true,
        // @ts-expect-error RTK's issue with union types
        data: action.payload.data,
      }
    })

    builder.addCase(cmsModalsActions.closeModal, (state, action) => {
      state.modals[action.payload] = {
        isOpen: false,
        data: null,
      }
    })

    builder.addMatcher(
      (action) =>
        [
          cmsFlowsActions.bulkDeleteFlows.fulfilled.type,
          cmsFlowsActions.bulkMoveTo.fulfilled.type,
          cmsFlowsActions.bulkRestoreFlows.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        if (action.payload === null) return

        state.ui.selectedFlowIds = []
        state.ui.lastToggledFlowId = null
        state.flows.list = state.flows.list.filter((flow) => !action.payload?.includes(flow.ns))
      },
    )

    builder.addMatcher(
      (action) =>
        [
          cmsFoldersActions.fetchFolders.fulfilled.type,
          cmsFoldersActions.fetchFolders.rejected.type,
        ].includes(action.type),
      (state) => {
        state.folders.isLoading = false
      },
    )

    builder.addMatcher(
      (action) =>
        [
          cmsFlowsActions.deleteFlowByPath.fulfilled.type,
          cmsFlowsActions.restoreFlow.fulfilled.type,
          cmsFlowsActions.permanentlyRemoveFlow.fulfilled.type,
          cmsFlowsActions.moveFlow.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        if (action.payload === null) return

        state.flows.list = state.flows.list.filter((flow) => flow.path !== action.payload.path)
      },
    )
  },
})

export const contentManagementActions = {
  ...cmsCombinedActions,
  ...actions,
  ...cmsFlowsActions,
  ...cmsFoldersActions,
  ...cmsFiltersActions,
  ...cmsUiActions,
  ...cmsQueryActions,
  ...cmsModalsActions,
}

const getState = (state: RootState) => state.contentManagementSystem

export const contentManagementSelectors = {
  getInitialized: (state: RootState) => getState(state).isInitialized,
  ...cmsFlowsSelectors,
  ...cmsFoldersSelectors,
  ...cmsFiltersSelectors,
  ...cmsQuerySelectors,
  ...cmsUiSelectors,
  ...cmsModalsSelectors,
}
