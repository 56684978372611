import { createElement } from 'react'
import { AnyAction } from 'redux'
import { l } from '@manychat/manyui'

import { fetchPro } from 'common/billing/actions/billingActions'
import { setupExperiment } from 'common/billing/actions/experimentActions'
import { upgrade } from 'common/billing/actions/upgradeActions'
import {
  abilityToAddOn,
  cancelUpgradeRequest,
  requestUpgrade,
} from 'common/billing/actions/upgradeRequestActions'
import ProAlert from 'common/billing/components/ProAlert'
import { BillingExperiment, ExperimentGroup } from 'common/billing/constants/BillingExperiment'
import { SubscriptionAction } from 'common/billing/constants/SubscriptionAction'
import { RequestUpgradePayload } from 'common/billing/interfaces'
import { nextActionModalActions } from 'common/billing/redux/nextActionModal'
import {
  getExperimentGroup,
  getHasBillingAccess,
  getIsCartFulfilled,
  getIsSubscriptionActionEqual,
} from 'common/billing/selectors/billingSelectors'
import { alert } from 'common/core'
import { waitForAction } from 'shared/lib/redux/waitForAction'
import { analyticsService } from 'utils/services/analytics'

import { BillingServiceInterface, ProAlertOptions } from './interfaces'
import * as paywalls from './paywalls'

export class BillingService implements BillingServiceInterface {
  public paywalls = paywalls

  public requestUpgrade = async (payload: RequestUpgradePayload): Promise<boolean> => {
    const { products = [], addOns = [], abilities = [] } = payload

    const isVerifyAction = getIsSubscriptionActionEqual(
      this.store.getState(),
      SubscriptionAction.CONTINUE_CHECKOUT,
    )

    if (isVerifyAction) {
      this.store.dispatch(nextActionModalActions.open())
      return false
    }

    await this.store.dispatch(fetchPro())

    const requestedAddOns = Array.from(
      new Set([...addOns, ...abilities.map((ability) => abilityToAddOn[ability])]),
    )

    const isCartFulfilled = getIsCartFulfilled(this.store.getState(), products, requestedAddOns)
    if (isCartFulfilled) {
      return true
    }

    this.store.dispatch(requestUpgrade(payload))

    const hasBillingAccess = getHasBillingAccess(this.store.getState())
    if (!hasBillingAccess) {
      return false
    }

    const endActions = [upgrade.fulfilled, cancelUpgradeRequest] as const
    type EndAction = ReturnType<typeof endActions[number]>
    const isEndAction = (action: AnyAction): action is EndAction =>
      endActions.reduce((acc, action) => acc || action.match(action), false)

    await waitForAction(isEndAction)

    return getIsCartFulfilled(this.store.getState(), products, requestedAddOns)
  }

  public setupExperiment = (experiment: BillingExperiment): ExperimentGroup | undefined => {
    this.store.dispatch(setupExperiment(experiment))

    return this.getExperimentGroup(experiment)
  }

  public getExperimentGroup = (experiment: BillingExperiment) => {
    const state = this.store.getState()

    return getExperimentGroup(state, experiment)
  }

  public proAlert = (options: ProAlertOptions): Promise<boolean> => {
    return new Promise((resolve) => {
      const { title, source, buttonLabel } = options
      const message =
        options.message ||
        options.proError?.message ||
        l.translate(
          "You aren't able activate this feature on the current plan. Upgrade for unlimited access.",
        )
      const modalTitle = title || options.proError?.message
      const products = options.products || options.proError?.error_data?.products
      const addOns = options.addOns || options.proError?.error_data?.addons

      const alertContent = createElement(ProAlert, {
        ...options,
        title: modalTitle,
        products,
        addOns,
        message,
        buttonLabel,
        onUpgrade: resolve,
      })

      analyticsService.sendEvent('SESSION_QUALITY.PRO_ALERT_VISIBLE', {
        source,
      })

      alert('', undefined, {
        isTemporary: false,
        icon: 'Warning',
        content: alertContent,
      })
    })
  }

  private get store() {
    return window.app.store
  }
}

export const billing = new BillingService()
