import { LayoutWithSidebar } from 'utils/router/components/LayoutWithSidebar/LayoutWithSidebar'

export const AiAlwaysOnAutomationRoutes = [
  {
    path: 'ai-always-on-automation',
    Component: LayoutWithSidebar,
    alias: 'aiAlwaysOnAutomation',
    children: [
      {
        index: true,
        lazy: () => import('./aiAlwaysOnAutomation.page'),
      },
    ],
  },
] as const
