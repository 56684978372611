import React, { createContext, useContext, PropsWithChildren } from 'react'

import { EasyBuilderCampaignType } from 'shared/api/requests/easyBuilder/types'

interface SidebarContextType {
  campaignType: EasyBuilderCampaignType
  widgetId?: number | null
  flowId?: string
}

const defaultSidebarContext: SidebarContextType = {
  campaignType: EasyBuilderCampaignType.IG_CGT_REPLY,
  widgetId: null,
}

const SidebarContext = createContext<SidebarContextType>(defaultSidebarContext)

export const useSidebarContext = () => {
  return useContext(SidebarContext)
}

export const SidebarContextProvider = ({
  campaignType,
  widgetId,
  flowId,
  children,
}: PropsWithChildren<SidebarContextType>) => {
  return (
    <SidebarContext.Provider
      value={{
        campaignType,
        widgetId,
        flowId,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}
