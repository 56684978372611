import { createSelector } from '@reduxjs/toolkit'
import omit from 'lodash/omit'
import { createStructuredSelector } from 'reselect'
import { Optional } from 'utility-types'

import { ChannelType } from 'common/core/constants/ChannelType'
import { ChannelStatus } from 'common/core/interfaces/channelStatuses'
import { getCurrentAccount } from 'common/core/selectors/appSelectors'
import { WhatsAppChannelState, WhatsAppConnectedState } from 'shared/api/requests/whatsApp/schemas'

//#region sms
export const getSMSChannel = createSelector(getCurrentAccount, (ca) => ca.sms_channel)

export const hasConnectedSMSChannel = (state: RootState): boolean => {
  return (
    Boolean(getSMSChannel(state).sms_channel_connected) &&
    Boolean(getSMSChannel(state).sms_has_phones)
  )
}

export const getSmsChannelStatus = createSelector(getSMSChannel, ({ status }) => status)

export const getSmsChannelConnectedTimestamp = (state: RootState): string | null =>
  getSMSChannel(state).ts_channel_connected
//#endregion

//#region email
export const getEmailChannel = createSelector(getCurrentAccount, (ca) => ca.email_channel)

export const isEmailChannelBanned = (state: RootState) => {
  return Boolean(getEmailChannel(state).email_channel_banned)
}

export const getIsEmailNodeEnabled = (state: RootState): boolean =>
  Boolean(getEmailChannel(state)?.enable_email_node ?? false)

export const getEmailChannelConnectedTimestamp = (state: RootState): string | null =>
  getEmailChannel(state).ts_channel_connected

export const getEmailChannelStatus = createSelector(getEmailChannel, ({ status }) => status)

//#endregion

//#region Instagram
export const getInstagramChannel = createSelector(getCurrentAccount, (ca) => ca.instagram_channel)

export const hasConnectedInstagramChannel = (state: RootState): boolean =>
  Boolean(getInstagramChannel(state)?.is_channel_connected)

export const getIsInstagramChannelEnabled = (state: RootState): boolean =>
  Boolean(getInstagramChannel(state)?.is_channel_enabled)

export const getIsInstagramChannelActive = (state: RootState) =>
  hasConnectedInstagramChannel(state) && getIsInstagramChannelEnabled(state)

export const getInstagramChannelConnectedTimestamp = (state: RootState) =>
  getInstagramChannel(state)?.ts_channel_connected || null

export const getInstagramChannelPageUnlinked = createSelector(
  [getInstagramChannel],
  (ig) => ig.is_ig_page_unlinked ?? null,
)

export const geInstagramChannelStatus = (state: RootState) => getInstagramChannel(state).status

export const showTelegramBannerForIgAccounts = (state: RootState): boolean =>
  getInstagramChannel(state).show_telegram_banner_for_ig_accounts

export const getInstagramAccountId = (state: RootState): number | null =>
  getInstagramChannel(state).business_id

export const isInstagramCGTAutoReplyEnabled = (state: RootState): boolean =>
  Boolean(getInstagramChannel(state).can_use_ig_auto_reply)

export const isInstagramBrandingMessageAvailable = (state: RootState): boolean => {
  const currentAccount = getCurrentAccount(state)
  const hasConnectedIGChannel = hasConnectedInstagramChannel(state)

  return Boolean(
    (currentAccount.isFree || currentAccount.isExpired) &&
      currentAccount.allow_free_branding &&
      hasConnectedIGChannel,
  )
}

export const getInstagramChannelIsNewApi = createSelector(
  getInstagramChannel,
  (instagramChannel) => instagramChannel.is_new_ig_api,
)

//#endregion

//#region facebook
export const getFBChannel = createSelector(getCurrentAccount, (ca) => ca.fb_channel)

export const isFBChannelConnected = (state: RootState): boolean =>
  Boolean(getFBChannel(state)?.fb_page_id)

export const hasEnabledFBChannel = (state: RootState): boolean =>
  getFBChannel(state)?.enable_fb_channel !== false

export const getFBChannelConnectedTimestamp = (state: RootState) =>
  getFBChannel(state)?.ts_channel_connected || null

export const isFBChannelDisabled = (state: RootState): boolean =>
  getFBChannel(state)?.is_fb_channel_disabled || false

export const getFBChannelMessagingPermissionError = createSelector([getFBChannel], (fb) =>
  Boolean(fb.has_pages_messaging_perm_processing_error),
)

export const getHasAnyStatusFBChannel = (state: RootState): boolean =>
  Boolean(getFBChannel(state)?.status && getFBChannel(state)?.status !== ChannelStatus.NONE)

export const getFbChannelStatus = (state: RootState) => getFBChannel(state).status

export const isFBChannelHasSubscriptionPermission = (state: RootState): boolean =>
  getFBChannel(state)?.has_subscription_permission || false
//#endregion

//#region whatsapp
export const getWhatsAppChannel = createSelector(getCurrentAccount, (ca) => ca.whatsapp_channel)

export const isWhatsAppChannelConnected = (
  channel: WhatsAppChannelState,
): channel is WhatsAppConnectedState => {
  return channel.state !== ChannelStatus.NONE
}

/**
 * use this selector in places where you sure WA channel SHOULD be connected
 */
export const getConnectedWhatsAppChannel = (state: RootState) => {
  const channel = getWhatsAppChannel(state)
  if (!isWhatsAppChannelConnected(channel)) {
    return null
  }

  return channel
}

export const getWhatsAppChannelStatus = createSelector(getWhatsAppChannel, (whatsAppChannel) => {
  if (!isWhatsAppChannelConnected(whatsAppChannel)) {
    return ChannelStatus.NONE
  }

  return whatsAppChannel.state
})

export const hasConnectedWhatsAppChannel = (state: RootState) => {
  return ['active', 'verification_pending'].includes(
    String(getCurrentAccount(state).whatsapp_channel.state),
  )
}

export const isWhatsAppChannelActive = (state: RootState) =>
  getCurrentAccount(state).whatsapp_channel.state === 'active'

export const isWhatsAppChannelBanned = (state: RootState) => {
  const channel = getCurrentAccount(state).whatsapp_channel
  if (!isWhatsAppChannelConnected(channel)) {
    return false
  }

  return channel.is_banned
}

export const whatsAppChannelHasCreditLineIssue = (state: RootState) => {
  return hasCreditLineIssue(state) || hasNoSharedCreditLine(state)
}

export const hasCreditLineIssue = (state: RootState) => {
  const channel = getCurrentAccount(state).whatsapp_channel
  if (!isWhatsAppChannelConnected(channel)) {
    return false
  }

  return channel.has_issue_with_credit_line
}

export const hasNoSharedCreditLine = (state: RootState) => {
  const channel = getCurrentAccount(state).whatsapp_channel
  if (!isWhatsAppChannelConnected(channel)) {
    return false
  }

  return !channel.has_credit_line && !channel.has_issue_with_credit_line
}

export const getWhatsAppChannelConnectedTimestamp = (state: RootState) => {
  const channel = getWhatsAppChannel(state)
  if (!isWhatsAppChannelConnected(channel)) return null
  return channel.ts_channel_connected || null
}

export const getWhatsAppChannelFullPhoneNumber = (state: RootState) => {
  const channel = getWhatsAppChannel(state)
  if (!isWhatsAppChannelConnected(channel)) return null

  return channel?.country_code && channel?.phone_number
    ? channel?.country_code + channel?.phone_number
    : null
}
//#endregion

//#region telegram
export const getTelegramChannel = createSelector(getCurrentAccount, (ca) => ca.telegram_channel)

export const getTelegramChannelStatus = (state: RootState) => getTelegramChannel(state).status

export const hasConnectedTelegramChannel = (state: RootState) =>
  getTelegramChannel(state).is_channel_connected

export const hasEnabledTelegramChannel = (state: RootState): boolean =>
  Boolean(getTelegramChannel(state)?.is_channel_enabled)

export const getTelegramChannelConnectedTimestamp = (state: RootState): string | null =>
  getTelegramChannel(state).ts_channel_connected
//#endregion

//#region tiktok
export const getTikTokChannel = createSelector(getCurrentAccount, (ca) => ca.tiktok_channel)

export const getTikTokChannelEnabled = (state: RootState): boolean =>
  Boolean(getTikTokChannel(state)?.is_channel_enabled)

export const getTiktokChannelStatus = createSelector(getTikTokChannel, (tikTokAccount) => {
  return tikTokAccount?.status ?? ChannelStatus.NONE
})

export const getTikTokChannelUsRegionRestricted = createSelector(
  getTikTokChannel,
  (tikTokAccount) => {
    return (
      tikTokAccount?.status === ChannelStatus.ERROR &&
      Boolean(tikTokAccount?.is_us_region_restricted)
    )
  },
)

export const getTiktokChannelConnectedTimestamp = (state: RootState): string | null =>
  getTikTokChannel(state)?.ts_channel_connected ?? null

export const hasConnectedTiktokChannel = (state: RootState): boolean =>
  Boolean(getTikTokChannel(state)?.is_channel_connected)
//#endregion

//#region mixed channels

export const getIsSmsOrEmailEnabled = (state: RootState): boolean => {
  return [getSMSChannel(state), getEmailChannel(state)].some((c) => c.status !== ChannelStatus.NONE)
}

export const getAllChannelStatuses = createStructuredSelector(
  {
    [ChannelType.EMAIL]: getEmailChannelStatus,
    [ChannelType.SMS]: getSmsChannelStatus,
    [ChannelType.INSTAGRAM]: geInstagramChannelStatus,
    [ChannelType.FB]: getFbChannelStatus,
    [ChannelType.TELEGRAM]: getTelegramChannelStatus,
    [ChannelType.WHATSAPP]: getWhatsAppChannelStatus,
    [ChannelType.TIKTOK]: getTiktokChannelStatus,
  },
  createSelector,
)

export type ReturnGetAllChannelStatuses = Optional<ReturnType<typeof getAllChannelStatuses>>

export const getChannelsConnectionMap = createStructuredSelector(
  {
    [ChannelType.INSTAGRAM]: hasConnectedInstagramChannel,
    [ChannelType.FB]: isFBChannelConnected,
    [ChannelType.WHATSAPP]: hasConnectedWhatsAppChannel,
    [ChannelType.TELEGRAM]: hasConnectedTelegramChannel,
    [ChannelType.TIKTOK]: hasConnectedTiktokChannel,
    [ChannelType.SMS]: hasConnectedSMSChannel,
    [ChannelType.EMAIL]: getIsEmailNodeEnabled,
  },
  createSelector,
)

export const getChannelsConnectionTimestampMap = createStructuredSelector(
  {
    [ChannelType.INSTAGRAM]: getInstagramChannelConnectedTimestamp,
    [ChannelType.FB]: getFBChannelConnectedTimestamp,
    [ChannelType.WHATSAPP]: getWhatsAppChannelConnectedTimestamp,
    [ChannelType.TELEGRAM]: getTelegramChannelConnectedTimestamp,
    [ChannelType.TIKTOK]: getTiktokChannelConnectedTimestamp,
    [ChannelType.SMS]: getSmsChannelConnectedTimestamp,
    [ChannelType.EMAIL]: getEmailChannelConnectedTimestamp,
  },
  createSelector,
)

export const getIsChannelConnected = createSelector(
  getChannelsConnectionMap,
  (_0: RootState, channel: ChannelType) => channel,
  (channelsConnectionMap, channel) => {
    // GUEST channel doesn't describe into the channels connection map
    if (channel === ChannelType.GUEST) return false

    return channelsConnectionMap[channel]
  },
)

export const isTheOnlyChannelEnabled = (
  statuses: ReturnGetAllChannelStatuses,
  channelName: ChannelType,
) => {
  return Object.entries(statuses).every(
    ([name, status]) =>
      (name === channelName && status !== ChannelStatus.NONE) ||
      (name !== channelName && status === ChannelStatus.NONE),
  )
}

/**
 * the selector does not depend on email and sms channels
 */
export const getIsInstagramOnlyEnabledNoEmailNoSms = createSelector(
  getAllChannelStatuses,
  (statuses) => {
    const exclude: Array<keyof typeof statuses> = [ChannelType.EMAIL, ChannelType.SMS]
    const statusesMap = omit(statuses, exclude)

    return isTheOnlyChannelEnabled(statusesMap, ChannelType.INSTAGRAM)
  },
)

export const getIsTiktokOnlyEnabled = createSelector(getAllChannelStatuses, (statuses) => {
  return isTheOnlyChannelEnabled(statuses, ChannelType.TIKTOK)
})

export const getIsSecondChannelConnected = createSelector(
  isFBChannelConnected,
  hasConnectedWhatsAppChannel,
  hasConnectedSMSChannel,
  hasConnectedInstagramChannel,
  hasConnectedTelegramChannel,
  hasConnectedTiktokChannel,
  (fb, whatsapp, sms, ig, tg, tt) => [fb, whatsapp, sms, ig, tg, tt].filter(Boolean).length > 1,
)

export const getHasAnyRelevantToTriggersConnectedChannels = (state: RootState) => {
  return (
    isFBChannelConnected(state) ||
    hasConnectedWhatsAppChannel(state) ||
    hasConnectedSMSChannel(state) ||
    hasConnectedInstagramChannel(state) ||
    hasConnectedTelegramChannel(state) ||
    hasConnectedTiktokChannel(state)
  )
}

export const hasConnectedEmailOrWhatsAppChannel = (state: RootState): boolean => {
  return getIsEmailNodeEnabled(state) || hasConnectedWhatsAppChannel(state)
}

export const getPageNameByChannel = (state: RootState, channel: ChannelType | null): string => {
  const account = getCurrentAccount(state)

  if (channel === ChannelType.FB) {
    return account.fb_channel.fb_page_name
  }

  if (channel === ChannelType.INSTAGRAM) {
    return account.instagram_channel.username
  }

  if (channel === ChannelType.WHATSAPP) {
    if (!isWhatsAppChannelConnected(account.whatsapp_channel)) {
      return account.title
    }

    return account.whatsapp_channel.business_account_name ?? account.title
  }

  if (channel === ChannelType.TELEGRAM) {
    return account.telegram_channel.bot_name
  }

  if (channel === ChannelType.TIKTOK) {
    return account.tiktok_channel?.tt_display_name ?? account.title
  }

  return account.title
}
