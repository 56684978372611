import { FsQueryOrderField, FsQueryOrderType, ViewDisplayMode } from 'apps/cms/lib/constants'
import { FlowCardClickAction } from 'apps/cms/pages/ContentManagementSystem/lib/types'
import { AutomationModalType, ContentManagementSystemState } from 'apps/cms/store/lib/types'

export const initialState: ContentManagementSystemState = {
  flows: {
    isLoaded: false,
    isLoading: false,
    list: [],
    limiter: null,
    accountHasFlows: false,
    lastRequestId: null,
    isCreating: false,
  },
  folders: {
    isLoaded: false,
    isLoading: false,
    list: [],
  },
  filters: {
    isLoaded: false,
    isLoading: false,
    triggerTypeFilters: [],
    triggerStatusFilters: [],
  },
  query: {
    search: '',
    path: '/',
    field: FsQueryOrderField.MODIFIED,
    order: FsQueryOrderType.DESC,
    triggerFilters: [],
    triggerStatus: null,
  },
  isInitialized: false,
  ui: {
    viewDisplayMode: ViewDisplayMode.LIST,
    // todo use flow path instead of id
    selectedFlowIds: [],
    lastToggledFlowId: null,
    flowCardClickAction: FlowCardClickAction.OPEN_FLOW,
    isFlowCreationStarted: false,
    lastSelectedFlowId: null,
    allowCreateFlow: true,
    allowCreateFolder: true,
    showBulkActions: true,
    showFlowContextMenu: true,
    showFolderContextMenu: true,
    showFolders: true,
    showModeSwitcher: true,
    showFlowCardActivationToggle: true,
    isOutlinedNewAutomationButton: false,
    showTrash: true,
    disableFlowCard: false,
    disableFlowCreation: false,
    disabledFlowCardMessage: null,
    customRootName: null,
    allowHoverFlowCard: false,
    allowHighlightActiveFlowCard: false,
    allowDragAndDrop: true,
    focusSearchOnMount: false,
  },
  modals: {
    [AutomationModalType.RENAME_FLOW]: { isOpen: false, data: null },
    [AutomationModalType.DELETE_FLOW]: { isOpen: false, data: null },
    [AutomationModalType.CONVERT_FLOW_CHANNELS]: { isOpen: false, data: null },
    [AutomationModalType.SHARE_FLOW]: { isOpen: false, data: null },
    [AutomationModalType.EXPORT_FLOW_MODAL]: { isOpen: false, data: null },
    [AutomationModalType.BULK_DELETE]: { isOpen: false, data: null },
    [AutomationModalType.PERMANENT_DELETE]: { isOpen: false, data: null },
    [AutomationModalType.MOVE_TO]: { isOpen: false, data: null },
    [AutomationModalType.BULK_MOVE_TO]: { isOpen: false, data: null },
    [AutomationModalType.CREATE_FOLDER]: { isOpen: false, data: null },
    [AutomationModalType.RENAME_FOLDER]: { isOpen: false, data: null },
    [AutomationModalType.DELETE_FOLDER]: { isOpen: false, data: null },
    [AutomationModalType.CREATE_FOLDER_WITH_CONTENT]: { isOpen: false, data: null },
  },
}
