import React, { ReactNode } from 'react'
import get from 'lodash/get'
import { Icon, l } from '@manychat/manyui'

import { UserRoles } from 'common/userRoles'
import { routesGroups } from 'utils/router/constants'
import { isPathnameMatchRoute } from 'utils/router/tools'

import { getCurrentAccountID } from './selectors/appSelectors'

export interface NavigationRouteHideParams {
  isPRO: boolean
  connectedProducts?: {
    has_inbox: boolean
  }
  userRole: UserRoles
  allowAnalyticsTabFlag: boolean
  isInstagramOnlyEnabled: boolean
  isTiktokOnlyEnabled: boolean
  isSMSorEmailEnabled: boolean
  hasAnyStatusFBChannel: boolean
  enableIGRecurringNotification: boolean
  isAdsEnabled: boolean
  isAiAlwaysOnAutomationEnabled: boolean
}

export interface NavigationRoute {
  label: string | ((state: RootState, props: SafeUnknownObject) => string)
  url: string
  navGroup?: boolean
  icon?: string | ReactNode | ((props: SafeUnknownObject) => string | ReactNode)
  testId: string
  onboardingId?: string
  items?: NavigationRoute[]
  isBeta?: boolean
  showNotifications?: boolean
  hide?: (props: NavigationRouteHideParams) => boolean
  badge?: (props: SafeUnknownObject) => string
  isActive?: (route?: string) => boolean
}

const broadcasts: NavigationRoute = {
  label: () => l.translate('Broadcasts'),
  url: '/posting',
  testId: 'broadcasts',
  items: [
    {
      url: '/drafts',
      label: () => l.translate('Drafts'),
      testId: 'broadcasts-drafts',
      items: [
        {
          label: (state, props) => {
            return get(state, `flow.byId.${props.flowId}.name`, '')
          },
          testId: 'broadcasts-drafts-item',
          url: '/:post_id',
        },
      ],
    },
    {
      url: '/scheduled',
      label: () => l.translate('Scheduled'),
      testId: 'broadcasts-scheduled',
      items: [
        {
          label: (state, props) => {
            return get(state, `flow.byId.${props.flowId}.name`, '')
          },
          testId: 'broadcasts-scheduled-item',
          url: '/:post_id',
        },
      ],
    },
    {
      url: '/history',
      label: () => l.translate('History'),
      testId: 'broadcasts-history',
      items: [
        {
          label: (state, props) => {
            return get(state, `flow.byId.${props.flowId}.name`, '')
          },
          testId: 'broadcasts-history-item',
          url: '/:post_id',
          items: [{ label: 'Responses', url: '/responses', testId: 'broadcasts-history-response' }],
        },
      ],
    },
  ],
}

export const newNav: NavigationRoute[] = [
  {
    label: () => l.translate('Home'),
    url: '/dashboard',
    icon: () => {
      return <Icon.Home />
    },
    isActive: () => routesGroups.HOME.some(isPathnameMatchRoute),
    testId: 'dashboard',
    items: [
      {
        label: 'Learn Manychat',
        url: '/module',
        testId: 'module',
      },
    ],
  },
  {
    label: () => l.translate('Contacts'),
    url: '/subscribers',
    icon: () => {
      return <Icon.User />
    },
    testId: 'contacts',
    onboardingId: 'contacts-nav-item',
  },
  {
    label: () => l.translate('Automation'),
    url: '/cms',
    icon: () => {
      return <Icon.Automation />
    },
    hide: (props) => props.userRole === UserRoles.AGENT,
    testId: 'cms',
  },
  {
    label: () => 'AI Assignments',
    url: '/ai-always-on-automation',
    icon: () => {
      return <Icon.AiFilled />
    },
    hide: (props) => props.userRole === UserRoles.AGENT || !props.isAiAlwaysOnAutomationEnabled,
    testId: 'ai',
  },
  {
    label: () => l.translate('Live Chat'),
    url: '/chat',
    icon: () => {
      return <Icon.LiveChat />
    },
    badge: (props) => (props.openedThreads ? String(props.openedThreads) : ''),
    testId: 'chat',
  },
  {
    label: () => l.translate('Analytics'),
    url: '/analytics',
    icon: () => {
      return <Icon.Analytics />
    },
    hide: (props) => {
      return !(props.allowAnalyticsTabFlag && props.userRole === UserRoles.ADMIN)
    },
    testId: 'analytics',
  },
  {
    label: () => l.translate('Broadcasting'),
    url: '',
    icon: () => {
      return <Icon.Broadcasting />
    },
    navGroup: true,
    testId: 'broadcasts',
    hide: (props) => {
      return (
        (props.enableIGRecurringNotification
          ? false
          : Boolean(props.isInstagramOnlyEnabled) && !props.isSMSorEmailEnabled) ||
        props.userRole === UserRoles.AGENT ||
        props.isTiktokOnlyEnabled
      )
    },
    items: [
      // Broadcasting
      broadcasts,
      {
        label: () => l.translate('Paid Messages'),
        url: '/sm',
        testId: 'sm',
        hide: (props) => !props.hasAnyStatusFBChannel || !props.isAdsEnabled,
        items: [
          {
            label: 'Settings',
            url: '/settings',
            testId: 'sm-settings',
          },
        ],
      },
    ],
  },

  {
    label: () => l.translate('Ads'),
    url: '/ads',
    testId: 'ads',
    icon: () => {
      return <Icon.Ads />
    },
    hide: (props) =>
      !props.hasAnyStatusFBChannel || props.userRole === UserRoles.AGENT || !props.isAdsEnabled,
    items: [
      {
        label: 'Ad',
        url: '/:ad_id',
        testId: 'growth-tools-ads',
      },
      {
        label: 'Settings',
        url: '/settings',
        testId: 'ads-settings',
      },
    ],
  },
  {
    showNotifications: true,
    label: () => l.translate('Settings'),
    url: '/settings',
    icon: () => <Icon.Settings />,
    testId: 'settings',
    isActive: () => routesGroups.SETTINGS.some(isPathnameMatchRoute),
    items: [
      {
        label: () => l.translate('Main Menu'),
        url: '/automation/menu',
        testId: 'main-menu',
        hide: () => true,
        items: [{ label: 'Edit', url: '/edit', testId: 'main-menu-edit' }],
      },
      {
        label: () => l.translate('Default Reply'),
        url: '/automation/default',
        testId: 'default-reply',
        hide: () => true,
        items: [
          { label: 'Edit', url: '/edit', testId: 'default-reply-edit' },
          { label: 'Responses', url: '/responses', testId: 'default-reply-responses' },
        ],
      },
      {
        label: () => l.translate('Welcome Message'),
        url: '/automation/welcome',
        testId: 'welcome-message',
        hide: () => true,
        items: [
          { label: 'Edit', url: '/edit', testId: 'welcome-message-edit' },
          { label: 'Responses', url: '/responses', testId: 'welcome-message-responses' },
        ],
      },
    ],
  },
]

const supportRoutes: NavigationRoute = {
  label: () => l.translate('Support'),
  url: '',
  testId: 'support',
  items: [
    {
      label: () => l.translate('Support'),
      url: '/support',
      testId: 'cms-flow-edit',
      items: [
        {
          label: () => l.translate('Technical Issue'),
          url: '/features',
          testId: 'support-features',
          items: [
            {
              label: 'Technical Issue',
              url: '/:feature',
              testId: 'support-context',
              items: [
                {
                  label: () => l.translate('Articles'),
                  url: '/articles',
                  testId: 'support-articles',
                  items: [
                    {
                      label: () => l.translate('Submit'),
                      url: '/issue',
                      testId: 'support-submit',
                    },
                  ],
                },
                {
                  label: () => l.translate('Submit'),
                  url: '/issue',
                  testId: 'support-submit',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

interface ISegmentInfo {
  active: boolean
  params: StringMapType
  url: string
}

const _getSegmentInfo = (loc: string[], segment: string[]): ISegmentInfo => {
  const res: ISegmentInfo = { active: false, params: {}, url: '' }
  if (loc.length < segment.length) {
    return res
  }
  for (let i = 0; i < segment.length; i++) {
    if (segment[i][0] === ':') {
      res.params[segment[i].slice(1)] = loc[i]
    } else if (loc[i] !== segment[i]) {
      return res
    }
    res.url += '/' + loc[i]
  }
  res.active = true
  return res
}

export const isPathActive = (path: string): boolean => {
  const paths = path.split('/').filter((p) => p)
  const locationPaths = window.location.pathname
    .split('/')
    .filter((p) => p)
    .slice(1)
  const { active } = _getSegmentInfo(locationPaths, paths)
  return active
}

interface IActiveRoute extends NavigationRoute {
  realUrl?: string
  params?: StringMapType
}

const _getActiveRoutes = (location: string[], routes: IActiveRoute[] = []): IActiveRoute[] => {
  const fallBack: IActiveRoute[] = []

  const nestedRoutes = (
    routes.length > 0 ? routes[routes.length - 1].items || fallBack : [...newNav, supportRoutes]
  ).reduce((res, route) => [...res, ...(route.url ? [route] : route.items || fallBack)], fallBack)

  for (const item of nestedRoutes) {
    if (item.url) {
      const paths = item.url.split('/').filter((p: string) => p)
      const { active, params, url } = _getSegmentInfo(location, paths)
      if (active) {
        routes.push({ ...item, params, realUrl: url })
        location = location.slice(paths.length)
        return _getActiveRoutes(location, routes)
      }
    }
  }
  return routes
}

interface IBreadrumb {
  url: string
  label: string | ((state: RootState, props: SafeUnknownObject) => string)
  testId?: string
}

export const getBreadcrumbs = (state: RootState, props: SafeUnknownObject): IBreadrumb[] => {
  const isPageScope = Boolean(getCurrentAccountID(state))
  const locationPaths = window.location.pathname
    .split('/')
    .filter((p) => p)
    .slice(Number(isPageScope))
  let baseUrl = ''
  return _getActiveRoutes(locationPaths, [])
    .map((r) => {
      baseUrl += r.realUrl
      return {
        url: baseUrl,
        label: typeof r.label === 'function' ? r.label(state, props) : r.label,
        testId: r.testId,
      }
    })
    .slice(0, -1)
}
