import { z } from 'zod'

import { BulkTypes } from 'apps/cms/lib/bulk'
import { FsQueryOrderField, FsQueryOrderType } from 'apps/cms/lib/constants'
import {
  StatusFilterLabel,
  TriggerFilterLabel,
} from 'apps/cms/pages/ContentManagementSystem/lib/filterLabels'
import { TriggerEventType } from 'apps/triggerRules/models/TriggerRule/constants'
import { SELECTION_TYPE } from 'common/cms/constants/CMSSelectionType'
import { FSObjectType } from 'common/cms/models/FSObject/constants'
import { ChannelType } from 'common/core/constants/ChannelType'
import { lightFlowSchema } from 'shared/api/common/schemas/lightFlow'
import {
  CmsIntentSchema,
  externalTriggerSchema,
  ruleTriggerSchema,
} from 'shared/api/requests/cms/schemas/fsCommonSchema'
import { fsFlowObjectBaseSchema } from 'shared/api/requests/cms/schemas/fsFlowObjectBaseSchema'
import { usedEntitiesListSchema } from 'shared/api/requests/cms/schemas/usedEntitiesList'
import { WidgetsSchema } from 'shared/api/requests/cms/schemas/widgets'
import { defaultReplySchema } from 'shared/api/requests/defaultReply/schemas'
import { EasyBuilderFlowStatus } from 'shared/api/requests/easyBuilder/schemas'
import { KeywordSchema } from 'shared/api/requests/keywords/schemas'
import { storyMentionReplySchema } from 'shared/api/requests/storyMentionReply/schemas'

import { cartReplySchema } from '../../cartReply/schemas'

export enum FlowTriggerStatus {
  DEFAULT = 'default',
  ACTIVE = 'active',
}

export const fsFlowSchema = z.object({
  type: z.literal(FSObjectType.FLOW),
  path: z.string(),
  ns: z.string(),
  name: z.string(),
  modified: z.union([z.number(), z.string()]),
  has_unpublished_changes: z.boolean(),
  has_published_content: z.boolean(),
  channels_usage: z.object({
    [ChannelType.FB]: z.boolean(),
    [ChannelType.INSTAGRAM]: z.boolean(),
    [ChannelType.SMS]: z.boolean(),
    [ChannelType.EMAIL]: z.boolean(),
    [ChannelType.TELEGRAM]: z.boolean(),
    [ChannelType.WHATSAPP]: z.boolean(),
    [ChannelType.TIKTOK]: z.boolean(),
  }),
  preview: z.object({
    url: z.string().nullable(),
    is_actual: z.boolean(),
  }),
  capabilities: z.object({
    is_editable: z.boolean(),
    is_shareable: z.boolean(),
    is_restorable: z.boolean(),
    is_deletable: z.boolean(),
    is_permanently_deletable: z.boolean(),
  }),
  stats: z.object({
    sent: z.number().nullable(),
    sent_unq: z.number().nullable(),
    clicked_unq: z.number().nullable(),
  }),
  triggers: z.object({
    widgets: WidgetsSchema,
    keywords: z.array(KeywordSchema),
    trigger_rules: z.array(ruleTriggerSchema),
    external_triggers: z.array(externalTriggerSchema),
    another_flow: z.array(
      z.object({
        title: z.string(),
      }),
    ),
    default_replies: z.array(defaultReplySchema),
    cart_replies: z.array(cartReplySchema),
    story_mentions: z.array(storyMentionReplySchema),
    welcome_messages: z.array(z.unknown()),
    follow_ups: z.array(z.unknown()),
    conversation_starters: z.array(z.unknown()),
    intents: z.array(CmsIntentSchema),
  }),
  sharing: z.object({
    player_page_url: z.string().nullable(),
    player_embed_url: z.string().nullable(),
    shared_cloning_enabled: z.boolean().nullable(),
    is_shared: z.boolean(),
  }),
  template_installation_access_data: z
    .object({
      has_user_access: z.boolean(),
      is_locked: z.boolean(),
      is_protected: z.boolean(),
      is_disabled: z.boolean(),
      is_owner: z.boolean(),
    })
    .optional(),
  easy_builder: z
    .object({
      status: z.nativeEnum(EasyBuilderFlowStatus),
    })
    .nullish(),
})

const folderEntitySchema = z.object({
  type: z.literal(FSObjectType.FOLDER),
  folder_id: z.number(),
  path: z.string(),
  title: z.string(),
  entity: z.number().optional(),
  created: z.number(),
  modified: z.number(),
})

const smartFolderEntitySchema = z.object({
  type: z.literal(FSObjectType.SMART_FOLDER),
  created: z.number(),
  modified: z.number(),
  path: z.string(),
  title: z.string(),
})

const usedEntityModelSchema = z.object({
  id: z.string(),
  title: z.string(),
})

const getFolderUsesListResponseSchema = z.object({
  entities: z.array(
    z.object({
      entities: usedEntitiesListSchema,
      model: usedEntityModelSchema,
    }),
  ),
})

const fsFlowObjectFullSchema = fsFlowObjectBaseSchema.extend({
  stats: z.object({
    sent: z.number().nullable(),
    sent_unq: z.number().nullable(),
    clicked_unq: z.number().nullable(),
  }),
  earned: z.string().nullable(),
  earned_list: z.union([z.array(z.string()), z.record(z.string())]),
})

const FlowLimiterSchema = z.union([z.number(), z.string()]).nullable()

const FlowsResponseSchema = z.object({
  limiter: FlowLimiterSchema,
  has_any_flows: z.boolean(),
  list: z.array(fsFlowSchema),
})

export enum FsFilterGroup {
  SMS = 'sms',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  CONTACT_EVENT = 'contact_event',
  WEB_WIDGET = 'web_widget',
  TELEGRAM = 'telegram',
  EXTERNAL_TRIGGER = 'external_trigger',
  WHATSAPP = 'whatsapp',
  TIKTOK = 'tiktok',
}

export enum FsTriggerModel {
  WIDGET = 'widget',
  INTENT = 'intent',
  KEYWORD = 'keyword',
  CONTACT_EVENT = 'contact_event',
  EXTERNAL_TRIGGER = 'external_trigger',
}

const fsFilterEntitySchema = z.object({
  channel: z.nativeEnum(ChannelType).nullable().optional(),
  trigger_model: z.nativeEnum(FsTriggerModel).nullable(),
  widget_type: z.number().nullable().optional(),
  event_type: z.nativeEnum(TriggerEventType).nullable().optional(),
})

const fsTriggerTypeFilterSchema = z.object({
  title: z.nativeEnum(TriggerFilterLabel),
  group: z.nativeEnum(FsFilterGroup),
  filters: fsFilterEntitySchema,
  key: z.string(),
})

const fsTriggerStatusFilterSchema = z.object({
  title: z.nativeEnum(StatusFilterLabel),
  filters: z.object({
    trigger_status: z.nativeEnum(FlowTriggerStatus),
  }),
})

export const cmsApiSchemas = {
  getUsesList: {
    response: z.object({
      entities: usedEntitiesListSchema,
    }),
    path: z.undefined(),
    query: z.object({
      flow_ns: z.string(),
    }),
  },
  delete: {
    query: z.object({
      path: z.string(),
      client_id: z.string().optional(),
      delete_content: z.boolean().optional(),
    }),
    response: z.object({
      path: z.string(),
      flows_were_moved: z.boolean().optional(),
    }),
    path: z.undefined(),
  },
  getFolderUsesList: {
    query: z.object({
      path: z.string(),
    }),
    response: z.object({
      entities: z.array(
        z.object({
          entities: usedEntitiesListSchema,
          model: usedEntityModelSchema,
        }),
      ),
    }),
    path: z.undefined(),
  },
  getFolders: {
    response: z.object({
      folders: z.array(folderEntitySchema),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },
  createFolder: {
    request: z.object({
      title: z.string(),
      path: z.string(),
    }),
    response: z.object({
      fs_object: folderEntitySchema,
    }),
    path: z.undefined(),
    query: z
      .object({
        client_id: z.string(),
      })
      .optional(),
  },
  renameObject: {
    request: z.object({
      client_id: z.string(),
      path: z.string(),
      title: z.string(),
    }),
    response: z.object({
      fs_object: z.union([folderEntitySchema, fsFlowObjectBaseSchema]),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },
  permanentlyDelete: {
    query: z.object({ path: z.string() }),
    response: z.object({ path: z.string() }),
    path: z.undefined(),
  },
  restore: {
    query: z.object({ path: z.string(), client_id: z.string().optional() }),
    response: z.object({ path: z.string() }),
    path: z.undefined(),
  },
  bulkMove: {
    request: z.object({
      paths: z.array(z.string()),
      to: z.string(),
      client_id: z.string().optional(),
    }),
    response: z.object({
      fs_objects: z.array(z.union([folderEntitySchema, fsFlowObjectBaseSchema])),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },
  bulkClone: {
    request: z.object({
      paths: z.array(z.string()),
      client_id: z.string().optional(),
    }),
    response: z.object({
      fs_objects: z.array(fsFlowObjectBaseSchema),
    }),
    path: z.undefined(),
    query: z.undefined(),
  },
  clone: {
    query: z.object({
      path: z.string(),
      client_id: z.string().optional(),
    }),
    response: z.object({
      fs_object: z.union([folderEntitySchema, fsFlowObjectBaseSchema]),
      path: z.string(),
    }),
    path: z.undefined(),
  },
  getBulkUsesList: {
    request: z.object({
      flow_nses: z.array(z.string()),
      path: z.string(),
      selection_type: z.string(),
    }),
    response: z.object({
      entities: z.array(
        z.object({
          entities: usedEntitiesListSchema,
          model: usedEntityModelSchema,
        }),
      ),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  convertFlow: {
    request: z.object({
      flow_ns: z.string(),
      convert_channels: z.record(z.nativeEnum(ChannelType)),
    }),
    response: z.object({
      fs_object: fsFlowObjectBaseSchema,
      path: z.string(),
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  createFlow: {
    request: z.object({
      name: z.string(),
      path: z.string().nullable(),
      quick_campaign_id: z.number().optional(),
    }),
    response: z.object({
      flow: lightFlowSchema,
      fs_object: fsFlowObjectBaseSchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  getFlows: {
    response: z.object({
      limiter: FlowLimiterSchema,
      has_any_flows: z.boolean(),
      list: z.array(fsFlowSchema),
    }),
    path: z.undefined(),
    query: z.object({
      limiter: FlowLimiterSchema.optional(),
      path: z.string().optional(),
      order: z.nativeEnum(FsQueryOrderType),
      field: z.nativeEnum(FsQueryOrderField),
    }),
  },
  bulk: {
    response: z.undefined(),
    path: z.undefined(),
    query: z.undefined(),
    request: z.object({
      path: z.string(),
      type: z.nativeEnum(BulkTypes),
      selection_type: z.nativeEnum(SELECTION_TYPE),
      flow_nses: z.array(z.string()),
      client_id: z.string(),
    }),
  },
  move: {
    query: z.object({
      path: z.string(),
      to: z.string(),
      client_id: z.string().optional(),
    }),
    response: z.object({
      fs_object: z.union([folderEntitySchema, fsFlowObjectBaseSchema]),
    }),
    path: z.undefined(),
    request: z.undefined(),
  },
  getFilters: {
    query: z.undefined(),
    path: z.undefined(),
    request: z.undefined(),
    response: z.object({
      trigger_type: z.array(fsTriggerTypeFilterSchema),
      trigger_status: z.array(fsTriggerStatusFilterSchema),
    }),
  },
  searchFlows: {
    query: z.undefined(),
    response: FlowsResponseSchema,
    path: z.undefined(),
    request: z.object({
      limiter: FlowLimiterSchema.optional(),
      order: z.nativeEnum(FsQueryOrderType),
      field: z.nativeEnum(FsQueryOrderField),
      path: z.string().optional(),
      q: z.string(),
      trigger_filters: z.array(fsFilterEntitySchema).nullable(),
      trigger_status: z.nativeEnum(FlowTriggerStatus).optional().nullable(),
    }),
  },
}

export type UsesList = z.infer<typeof usedEntitiesListSchema>
export type BulkUsesListResponse = z.infer<typeof getFolderUsesListResponseSchema>

export type FsFolderObject = z.infer<typeof folderEntitySchema>
export type FsSmartFolderObject = z.infer<typeof smartFolderEntitySchema>
export type FsFlowBaseObject = z.infer<typeof fsFlowObjectBaseSchema>
export type FsFlowObject = z.infer<typeof fsFlowObjectFullSchema>
export type FsFlow = z.infer<typeof fsFlowSchema>
export type FsObject =
  | FsFolderObject
  | FsFlowObject
  | FsFlowBaseObject
  | FsSmartFolderObject
  | FsFlow

export type FsFilter = z.infer<typeof fsTriggerTypeFilterSchema>
export type FsTriggerStatusFilter = z.infer<typeof fsTriggerStatusFilterSchema>

export type RuleTrigger = z.infer<typeof ruleTriggerSchema>
export type IntentTrigger = z.infer<typeof CmsIntentSchema>
export type ExternalTrigger = z.infer<typeof externalTriggerSchema>
