import { FsQueryOrderField, FsQueryOrderType, ViewDisplayMode } from 'apps/cms/lib/constants'
import { FlowCardClickAction } from 'apps/cms/pages/ContentManagementSystem/lib/types'
import { FSObjectType } from 'common/cms/models/FSObject/constants'
import {
  FsFilter,
  FsFlow,
  FsFolderObject,
  FlowTriggerStatus,
  FsTriggerStatusFilter,
} from 'shared/api/requests/cms/schemas'

export interface CmsModalsState {
  [AutomationModalType.RENAME_FLOW]: {
    isOpen: boolean
    data: { flowName: string; flowPath: string } | null
  }
  [AutomationModalType.DELETE_FLOW]: {
    isOpen: boolean
    data: { flowNs: string; flowPath: string; flowName: string } | null
  }
  [AutomationModalType.CONVERT_FLOW_CHANNELS]: {
    isOpen: boolean
    data: { flowNs: string } | null
  }
  [AutomationModalType.SHARE_FLOW]: {
    isOpen: boolean
    data: { flowId: string; flowSharingSettings: FsFlow['sharing'] } | null
  }
  [AutomationModalType.EXPORT_FLOW_MODAL]: {
    isOpen: boolean
    data: { flowId: string; flowName: string } | null
  }
  [AutomationModalType.PERMANENT_DELETE]: {
    isOpen: boolean
    data: { flowName: string; flowId: string; flowPath: string } | null
  }
  [AutomationModalType.MOVE_TO]: {
    isOpen: boolean
    data: { type: FSObjectType.FOLDER | FSObjectType.FLOW; name: string; path: string } | null
  }
  [AutomationModalType.BULK_MOVE_TO]: {
    isOpen: boolean
    data: null
  }
  [AutomationModalType.BULK_DELETE]: {
    isOpen: boolean
    data: { isPermanent: boolean } | null
  }
  [AutomationModalType.CREATE_FOLDER]: {
    isOpen: boolean
    data: null
  }
  [AutomationModalType.RENAME_FOLDER]: {
    isOpen: boolean
    data: { folderPath: string; folderName: string } | null
  }
  [AutomationModalType.DELETE_FOLDER]: {
    isOpen: boolean
    data: { folderPath: string; folderName: string } | null
  }
  [AutomationModalType.CREATE_FOLDER_WITH_CONTENT]: {
    isOpen: boolean
    data: { flowIds: string[] } | null
  }
}

export interface ContentManagementSystemState {
  flows: {
    isLoaded: boolean
    isLoading: boolean
    isCreating: boolean
    list: FsFlow[]
    limiter: number | string | null
    accountHasFlows: boolean
    lastRequestId: string | null
  }
  folders: {
    isLoaded: boolean
    isLoading: boolean
    list: FsFolderObject[]
  }
  filters: {
    isLoaded: boolean
    isLoading: boolean
    triggerTypeFilters: FsFilter[]
    triggerStatusFilters: FsTriggerStatusFilter[]
  }
  query: {
    search: string
    path: string
    order: FsQueryOrderType
    field: FsQueryOrderField
    triggerFilters: Array<FsFilter['filters']>
    triggerStatus: FlowTriggerStatus | null
  }
  isInitialized: boolean
  ui: {
    viewDisplayMode: ViewDisplayMode
    selectedFlowIds: string[]
    lastToggledFlowId: string | null
    lastSelectedFlowId: string | null
    flowCardClickAction: FlowCardClickAction
    isFlowCreationStarted: boolean
    allowCreateFlow: boolean
    allowCreateFolder: boolean
    allowDragAndDrop: boolean
    showBulkActions: boolean
    showFlowContextMenu: boolean
    showFolderContextMenu: boolean
    showFolders: boolean
    showModeSwitcher: boolean
    showTrash: boolean
    disableFlowCard: boolean
    disableFlowCreation: boolean
    showFlowCardActivationToggle: boolean
    isOutlinedNewAutomationButton: boolean
    disabledFlowCardMessage: string | null
    customRootName: string | null
    allowHoverFlowCard: boolean
    allowHighlightActiveFlowCard: boolean
    focusSearchOnMount: boolean
  }
  modals: CmsModalsState
}

export enum AutomationModalType {
  RENAME_FLOW = 'RENAME_FLOW',
  DELETE_FLOW = 'DELETE_FLOW',
  CONVERT_FLOW_CHANNELS = 'CONVERT_FLOW_CHANNELS',
  SHARE_FLOW = 'SHARE_FLOW',
  EXPORT_FLOW_MODAL = 'EXPORT_FLOW_MODAL',
  BULK_DELETE = 'BULK_DELETE',
  PERMANENT_DELETE = 'PERMANENT_DELETE',
  MOVE_TO = 'MOVE_TO',
  BULK_MOVE_TO = 'BULK_MOVE_TO',
  RENAME_FOLDER = 'RENAME_FOLDER',
  CREATE_FOLDER = 'CREATE_FOLDER',
  CREATE_FOLDER_WITH_CONTENT = 'CREATE_FOLDER_WITH_CONTENT',
  DELETE_FOLDER = 'DELETE_FOLDER',
}

export type SocketFsFlow = Omit<FsFlow, 'stats'>

export interface SocketFsFlowDeleted extends SocketFsFlow {
  source_ns: string
}
