import { createAction } from '@reduxjs/toolkit'
import { appListenerMiddleware } from 'reduxTyped'
import { v4 as uuid } from 'uuid'

import { PRO_MODAL_PRODUCTS, PRO_MODAL_TYPE } from 'common/billing/constants'
import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import { formatUpgradeSource } from 'common/builder/utils/upgradeEventUtils'
import { Ability } from 'common/core/constants/Ability'
import { ProProduct } from 'common/core/interfaces/products'
import { createAsyncAction } from 'shared/lib/redux'
import { analyticsService } from 'utils/services/analytics'

import { UpgradeRequest } from '../billingInterfaces'
import { UpgradeModalVersion } from '../constants'
import { SubscriptionAction } from '../constants/SubscriptionAction'
import { getPageInfo } from '../helpers/getPageInfo'
import { redirectToReturnParam } from '../helpers/redirectToReturnParam'
import { RequestUpgradePayload, UpgradeSourceType } from '../interfaces'
import {
  getPageAndHasBillingAccess,
  getSubscriptionAction,
  getUpgradeModalVersion,
} from '../selectors/billingSelectors'

import { setupExperiment } from './experimentActions'

interface AnalyticsOptions {
  source: UpgradeSourceType
  products: ProProduct[]
  addOns?: AddOnSlug[]
  proNodes?: string[]
  modalVersion: UpgradeModalVersion
  subscriptionAction: SubscriptionAction
  uuid: string
}

const sendAnalytics = ({
  source,
  products = [],
  addOns = [],
  proNodes = [],
  modalVersion,
  subscriptionAction,
}: AnalyticsOptions) => {
  const formattedSource = formatUpgradeSource(source)
  const { pathname } = window.location
  const analyticsOptions = {
    page: pathname,
    source: formattedSource,
    products,
    addOns,
    proNodes,
    modalVersion,
    subscriptionAction,
  }

  analyticsService.sendEvent('BILLING.UPGRADE.MODALVIEW', { ...analyticsOptions, PRO_MODAL_TYPE })
}

export const abilityToAddOn: Record<Ability, AddOnSlug> = {
  [Ability.AI_KEYWORD]: AddOnSlug.AI,
  [Ability.AI_AGENT]: AddOnSlug.AI,
}

export const requestUpgrade = createAsyncAction(
  'billing/requestUpgrade',
  async (payload: RequestUpgradePayload, { getState, dispatch }) => {
    const {
      source,
      title,
      products,
      addOns = [],
      strictRequest = false,
      abilities = [],
      experiment,
      ...options
    } = payload

    const state = getState()
    const modalVersion = getUpgradeModalVersion(state)
    const promotedProducts: ProProduct[] = products?.length ? [...products] : PRO_MODAL_PRODUCTS
    const subscriptionAction = getSubscriptionAction(state)

    const requestedAddOns = Array.from(
      new Set([...addOns, ...abilities.map((ability) => abilityToAddOn[ability])]),
    )

    sendAnalytics({
      source,
      products: promotedProducts,
      addOns: requestedAddOns,
      proNodes: options.proNodes,
      modalVersion,
      subscriptionAction,
      uuid: uuid(),
    })

    if (experiment) {
      dispatch(setupExperiment(experiment))
    }

    dispatch(
      createUpgradeRequest({
        title,
        source,
        ...options,
        products: promotedProducts,
        addOns: requestedAddOns,
        strictRequest,
        experiment,
        uuid: uuid(),
        paywall: options.paywall || null,
      }),
    )
  },
)

export const createUpgradeRequest = createAction<Partial<UpgradeRequest>>(
  'billing/createUpgradeRequest',
)
export const cancelUpgradeRequest = createAction('billing/cancelUpgradeRequest')

/**
 * The effect should redirect user to "redirect" param
 * on cancel Upgrade request
 */
appListenerMiddleware.startListening({
  matcher: cancelUpgradeRequest.match,
  effect: (_, { getState }) => {
    const state = getState()
    const { page } = getPageAndHasBillingAccess(state)
    const { isOnUpgradePage, returnParam, returnSuccessParam } = getPageInfo()

    const getReturnParam = () => {
      if (returnParam) {
        return returnParam
      }

      return page ? `/${page.id}/settings#billing` : ''
    }

    if (isOnUpgradePage) {
      redirectToReturnParam({
        page,
        returnParam: getReturnParam(),
        returnSuccessParam,
      })
    }
  },
})
