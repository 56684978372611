import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useAppSelector } from 'reduxTyped'

import { CmsDragSource, FlowDragItem } from 'apps/cms/lib/types'
import { contentManagementSelectors } from 'apps/cms/store'
import { usePermission, PermissionTargets } from 'common/userRoles'
import { FsFlow } from 'shared/api/requests/cms/schemas'
import { useDidMountEffect } from 'utils/commonHooks'

export const useFlowCardDrag = ({ flow }: { flow: FsFlow | undefined }) => {
  const userCanEditFlow = usePermission(PermissionTargets.FLOW_EDITING)

  const isTrashFolder = useAppSelector(contentManagementSelectors.getIsTrashFolder)
  const { allowDragAndDrop } = useAppSelector(contentManagementSelectors.getUi)

  const [, connectDragSource, dragPreview] = useDrag({
    item: {
      type: CmsDragSource.FLOW_CARD,
      path: flow?.path ?? '',
      id: flow?.ns ?? '',
    } as FlowDragItem,
    type: CmsDragSource.FLOW_CARD,

    canDrag: () => !isTrashFolder && userCanEditFlow && allowDragAndDrop,
  })

  useDidMountEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true, anchorX: 1, anchorY: 1 })
  })

  return {
    connectDragSource,
  }
}
